import React, { useEffect, useRef, useState } from "react";
import { SearchIcon } from "../Icons";
import {
  AutoCompleteContainer,
  AutocompleteSuggestion,
  AutocompleteSuggestions,
} from "./styles";

const AutoCompleteInput = ({ candidates, onCompleted, ...others }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);

  const getSuggestions = (inputValue) => {
    return candidates.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // Callback function to handle input changes
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setSuggestions(getSuggestions(newValue));
  };

  // Callback function to handle suggestion selection
  const handleSuggestionClick = (suggestion) => {
    setValue(suggestion);
    setSuggestions([]);
    onCompleted(suggestion)
  };

  useEffect(() => {
    const handleInputFocus = () => {
      if (!value) return ;
      setSuggestions(getSuggestions(value));
    };

    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };

    inputRef.current.addEventListener('focus', handleInputFocus);
    document.addEventListener("click", handleClickOutside);

    return () => {
      if (inputRef.current) inputRef.current.removeEventListener('focus', handleInputFocus);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <AutoCompleteContainer>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          value={value}
          ref={inputRef}
          onChange={handleInputChange}
          {...others}
        />
        <div className="input-group-append">
          <button className="btn btn-default" type="button">
            <span className="icon">
              <SearchIcon />
            </span>
          </button>
        </div>
      </div>
      {suggestions.length > 0 && (
        <AutocompleteSuggestions>
          {suggestions.map((suggestion) => (
            <AutocompleteSuggestion
              key={suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </AutocompleteSuggestion>
          ))}
        </AutocompleteSuggestions>
      )}
    </AutoCompleteContainer>
  );
};

export default AutoCompleteInput;
