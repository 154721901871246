import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import raf from "rc-util/lib/raf";
import React, { useRef, useState } from "react";

const HandleTooltip = (props) => {
  const {
    value,
    children,
    visible,
    tipFormatter = (val) => `${val} %`,
    ...restProps
  } = props;

  const tooltipRef = useRef();
  const rafRef = useRef(null);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forceAlign();
    });
  }

  React.useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: "auto" }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

const TooltipSlider = ({ tipFormatter, tipProps, ...props }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const tipHandleRender = (node, handleProps) => (
    <HandleTooltip
      value={handleProps.value}
      visible={handleProps.dragging || handleProps.index === hoveredIndex}
      tipFormatter={tipFormatter}
      onMouseEnter={() => handleMouseEnter(handleProps.index)}
      onMouseLeave={handleMouseLeave}
      {...tipProps}
    >
      {node}
    </HandleTooltip>
  );

  return (
    <Slider
      {...props}
      handleRender={tipHandleRender}
      style={{ marginBottom: 20 }}
    />
  );
};

export default TooltipSlider;
