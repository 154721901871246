import React, { useEffect, useState } from "react";
import {
  Toolbar,
  ToolbarItems,
  ToolbarToggleGrid,
  ToolbarList,
  Showing,
  Pages,
  PageList,
  PageItem,
  PerPage,
} from "../ToolbarStyle";
import { ListIcon, GridIcon } from "../Icons";

const Pagination = ({
  count,
  pageNo,
  changePageNo,
  pageSize,
  changePageSize,
  layoutType,
  changeLayout,
  scrollToStart,
}) => {
  if (count === 0) return null;

  const numberOfPages = Math.ceil(count / pageSize);
  const startIndex = (pageNo - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  let beforeDot = 0;
  let afterDot = 0;

  return (
    <Toolbar className="toolbar">
      <ToolbarItems className="toolbar-items">
        <ToolbarList className="toolbar-list">
          <Showing className="showing">
            <span>
              Showing {startIndex + 1} - {endIndex > count ? count : endIndex}{" "}
              of <strong>{count}</strong> items{" "}
            </span>
          </Showing>

          <Pages className="pages">
            <PageList role="navigation" aria-label="Pagination">
              {pageNo > 1 && (
                <PageItem
                  className="page next"
                  onClick={() => {
                    changePageNo(pageNo - 1);
                    scrollToStart();
                  }}
                >
                  <a href="#">&lt;</a>
                </PageItem>
              )}
              {Array.from({ length: numberOfPages }).map((_, i) => {
                if (i + 1 > 1 && i < pageNo - 2) {
                  beforeDot = beforeDot + 1;
                  if (beforeDot === 1) {
                    return <span key={i}>...</span>;
                  } else {
                    return null;
                  }
                } else if (i + 1 > pageNo + 1 && i + 1 < numberOfPages) {
                  afterDot = afterDot + 1;
                  if (afterDot === 1) {
                    return <span key={i}>...</span>;
                  } else {
                    return null;
                  }
                } else {
                  return (
                    <PageItem
                      className="page active"
                      onClick={() => {
                        changePageNo(i + 1);
                        scrollToStart();
                      }}
                    >
                      {pageNo === i + 1 ? (
                        <span>{i + 1}</span>
                      ) : (
                        <span className="selectable-pageno">{i + 1}</span>
                      )}
                    </PageItem>
                  );
                }
              })}
              {pageNo < numberOfPages && (
                <PageItem
                  className="page next"
                  onClick={() => {
                    changePageNo(pageNo + 1);
                    scrollToStart();
                  }}
                >
                  <a href="#">&gt;</a>
                </PageItem>
              )}
            </PageList>
          </Pages>

          <PerPage className="per-page">
            <a className="disabled">Per page:</a>
            {[15, 30, 60].map((ps, i) => (
              <span
                key={i}
                className={pageSize === ps ? "page-size active" : "page-size"}
                onClick={() => {
                  changePageSize(ps);
                  scrollToStart();
                }}
              >
                {ps}
              </span>
            ))}
          </PerPage>
        </ToolbarList>
      </ToolbarItems>
      <ToolbarToggleGrid>
        <button
          className={
            layoutType !== "grid"
              ? "toggle-grid-button"
              : "toggle-grid-button active"
          }
          onClick={() => changeLayout("grid")}
        >
          <span className="toggle-grid-icon">
            <GridIcon />
          </span>
        </button>

        <button
          className={
            layoutType !== "list"
              ? "toggle-grid-button"
              : "toggle-grid-button active"
          }
          onClick={() => changeLayout("list")}
        >
          <span className="toggle-grid-icon">
            <ListIcon />
          </span>
        </button>
      </ToolbarToggleGrid>
    </Toolbar>
  );
};
export default Pagination;
