import styled from "styled-components";

export const Toolbar = styled.div`
  margin: 0 -5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const ToolbarItems = styled.div`
  position: relative;
  padding: 0 5px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;
export const ToolbarToggleGrid = styled.div`
  display: flex;
  position: relative;
  padding: 0 5px;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  @media (max-width: 767.98px) {
    display: none;
  }
  .toggle-grid-button {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #eeeeee;
    padding: 6px;
    .toggle-grid-icon {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 18px;
      height: 18px;
      > svg {
        height: 18px;
        fill: #888;
      }
    }
    .toggle-grid-text {
      display: inline-flex;
      font-size: 10px;
      line-height: 12px;
    }
    &:hover {
      svg {
        fill: #000;
      }
    }

    &.active {
      svg {
        fill: #000;
      }
    }
  }
`;
export const ToolbarList = styled.div`
  display: flex;
  align-items: center;
  background: #eee;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 20px;
  justify-content: center;
  @media (min-width: 576px) {
    justify-content: space-between;
  }
`;
export const Showing = styled.div`
  color: #868686;
  @media (max-width: 575.98px) {
    display: none;
  }
  strong {
    font-weight: 700;
    color: #000;
  }
`;
export const PerPage = styled.div`
  flex-wrap: wrap;
  margin: 0 -5px;
  display: none;
  @media (min-width: 576px) {
    display: flex;
  }
  > a,
  .page-size {
    position: relative;
    font-weight: 600;
    padding: 0 5px;
    color: #868686;
    &:hover {
      color: #000;
    }
    &.disabled {
      color: #868686;
      font-weight: 400;
    }
    &.active {
      color: #000;
    }
  }

  .page-size {
    cursor: pointer;
  }
`;
export const Pages = styled.div``;
export const PageList = styled.div`
  list-style: none;
  margin: 0 -5px;
  padding: 0;
  display: flex;
  align-items: center;
`;
export const PageItem = styled.div`
  position: relative;
  padding: 0 5px;
  .pages-label {
  }
  .page {
    &.active {
    }
  }
`;
