import styled from "styled-components";

export const AutoCompleteContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const AutocompleteSuggestions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

export const AutocompleteSuggestion = styled.div`
  padding: 4px 16px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #EfEFEF;
  }
`;
